// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-plan-your-visit-js": () => import("./../../../src/pages/plan-your-visit.js" /* webpackChunkName: "component---src-pages-plan-your-visit-js" */),
  "component---src-pages-previous-events-js": () => import("./../../../src/pages/previous-events.js" /* webpackChunkName: "component---src-pages-previous-events-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-competitions-js": () => import("./../../../src/templates/competitions.js" /* webpackChunkName: "component---src-templates-competitions-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-primary-page-js": () => import("./../../../src/templates/primary-page.js" /* webpackChunkName: "component---src-templates-primary-page-js" */),
  "component---src-templates-secondary-page-js": () => import("./../../../src/templates/secondary-page.js" /* webpackChunkName: "component---src-templates-secondary-page-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */)
}

